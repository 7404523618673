// Styles
import styles from './TransitionGroup.module.scss';

// Vender
import { Transition, TransitionStatus } from 'react-transition-group';
import { useRef, ReactNode } from 'react'

interface Props {
  exited: object,
  exiting?: object | null,
  entering?: object | null,
  entered: object,
  duration: number,
  visable?: boolean,
  children: ReactNode
}

function TransitionGroup({exiting, exited, entering, entered, duration, visable = true, children} : Props) {
  const nodeRef = useRef(null);

  const transition = `${duration}ms ease-in-out`

  const transitionStyles = {
    entering: entering ? entering : entered,
    entered:  entered,
    exiting:  exiting ? exiting : exited,
    exited:  exited,
    unmounted: exited
  };

  return (
    <Transition nodeRef={nodeRef} in={visable} timeout={duration} mountOnEnter unmountOnExit>
      {(state: TransitionStatus) => {
        return (
          <div 
            className={styles.TransitionGroup}
            ref={nodeRef}
            style={{
              transition,
              ...exited,
              ...transitionStyles[state]
            }}
          >
            {children}
          </div>
        )
      }}
    </Transition>
  );
}

export default TransitionGroup;
