// Styles
import styles from './Development.module.scss';

// NPM Packages 
import cx from 'classnames';

// Components
import Button from '../Button';
import TransitionGroup from '../TransitionGroup';

// Other
import {Development as DevelopmentInterface} from '../../config'

interface DevelopmentProps {
  active: boolean,
  setToActive: () => void,
  development: DevelopmentInterface
}

function Development({active, setToActive, development} : DevelopmentProps) {
  // const [backgroundImage, setBackgroundImage] = useState<string>(config.indexImage);

  return (
    <div 
      className={cx(styles.Development, {[styles['Development--active']] : active})}
      style={{
        backgroundImage: `url(${development.bgPath})`
      }}
      onClick={(e) => {
        e.stopPropagation()
        setToActive()
      }}
    >
      <div className={styles.LogoContainer}>
        <img className={styles.Logo} src={development.logoPath} alt={development.name}/>
      </div>
      <TransitionGroup 
        exited={{
          maxHeight: 0,
          opacity: 0,
          transform: 'translateY(1rem)'
        }}
        exiting={{
          maxHeight: 0,
          opacity: 0, 
          transform: 'translateY(1rem)' 
        }}
        entered={{ 
          maxHeight: 300,
          opacity: 1, 
          transform: 'translateY(0)' 
        }}
        duration={300}
        visable={active}
      >
        <Button text="Discover" href={development.url} style="light"/>
      </TransitionGroup>
      
    </div>
  );
}

export default Development;
