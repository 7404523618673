// Styles
import styles from './BackgroundImage.module.scss';

// Vender
import { Transition, TransitionStatus, SwitchTransition } from 'react-transition-group';
import { useRef, ReactNode } from 'react'

interface Props {
  src: string
}

function BackgroundImage({src} : Props) {
  const nodeRef = useRef(null);

  const duration = 300

  const transition = {transition: `all ${duration}ms linear`}

  const testTransitionStyles = {
    entering: {border: '6px solid red'},
    entered:  {border: '6px solid green'},
    exiting:  {border: '6px solid blue'},
    exited:  {border: '6px solid purple'},
    unmounted: {border: '6px solid orange'},
  };

  const transitionStyles = {
    entering: { opacity: 0},
    entered:  { opacity: 1},
    exiting:  {opacity: 0},
    exited:  {opacity: 0},
    unmounted: {opacity: 0},
  };

  return (
    <div className={styles.BackgroundImage}>
      <SwitchTransition mode="in-out">
        <Transition key={src} nodeRef={nodeRef} in={true} timeout={duration}>
          {(state: TransitionStatus) => {
            console.log(state)
            return (
              <img 
                ref={nodeRef}
                style={{
                  ...transition,
                  ...transitionStyles[state]
                }}
                src={src}
              />
            )
          }}
        </Transition>
      </SwitchTransition>
    </div>
  );
}

export default BackgroundImage;
