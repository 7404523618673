// Styles
import styles from './ResponsiveDevelopement.module.scss';

// Components
import Button from '../Button';

// Other
import {Development as DevelopmentInterface} from '../../config'

interface ResponsiveDevelopement {
  development: DevelopmentInterface
}

function ResponsiveDevelopement({development} : ResponsiveDevelopement) {

  return (
    <div 
      className={styles.ResponsiveDevelopement}
      style={{
        backgroundImage: `url(${development.bgPath})`
      }}
    >
      <div className={styles.Container}>
        <img className={styles.Logo} src={development.logoPath} alt={development.name}/>
        <Button text="Discover" href={development.url} style="dark"/>
      </div>
    </div>
  );
}

export default ResponsiveDevelopement;
