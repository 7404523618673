// React
import { useEffect, useState } from 'react';

// Components
import Developement from '../Developement';
import ResponsiveDevelopement from '../ResponsiveDevelopement';
import BackgroundImage from '../BackgroundImage';

//Hooks
import useWindowDims from '../../hooks/useWindowDims';

// Styles
import styles from './App.module.scss';

// Vender
import cx from 'classnames'

// Other
import {config} from '../../config';


function App() {
  const { windowHeight, windowWidth } = useWindowDims();

  const [activeDevIndex, setActiveDevIndex] = useState<number | null>(null)
  // const [backgroundImage, setBackgroundImage] = useState<string>(config.indexImagePath);

  const isSmallScreen = windowWidth < 1000;

  const developments = !isSmallScreen ? config.developments.map((development, index) => {
    return <Developement 
      active={activeDevIndex === index ? true : false} 
      setToActive={() => setActiveDevIndex(index)}
      development={development}
      key={index}
    />
  }) : config.developments.map((development, index) => {
    return <ResponsiveDevelopement
      development={development}
      key={index}
    />
  })

  return (
    <div 
      className={cx(styles.App, {[styles.OverflowAuto]: windowWidth < 1000})} 
      style={{
        // backgroundImage: `url(${config.indexImagePath})` // Keep as backup, changing images too fast can result in a few ms with no image visable.
        backgroundImage: `url(${activeDevIndex !== null ? config.developments[activeDevIndex].imagePath : config.indexImagePath})` // Keep as backup, changing images too fast can result in a few ms with no image visable.
      }}
      onClick={() => setActiveDevIndex(null)}  
    >
      {!isSmallScreen && <BackgroundImage src={activeDevIndex !== null ? config.developments[activeDevIndex].imagePath : config.indexImagePath} />}
      {developments}
    </div>
  );
}

export default App;
