// Import images
import indexImage from './images/AERIAL_CGI_HR_Web.jpg'

import groveLogo from './images/theGroveLogo.svg'
import groveBg from './images/the-grove-bg.jpg'
import groveImage from './images/AERIAL_Grove.jpg'

import oaksLogo from './images/oaksLogo.svg'
import oaksBg from './images/the-oaks-bg.jpg'
import oaksImage from './images/AERIAL_Oaks.jpg'

interface Development {
    name: string,
    logoPath: string,
    bgPath: string,
    imagePath: string,
    url: string,
}

const config: {
    indexImagePath: string,
    developments: Array<Development>
} = {
    indexImagePath: indexImage,
    developments: [
        {
            name: 'The Grove',
            logoPath: groveLogo,
            bgPath: groveBg,
            imagePath: groveImage,
            url: 'https://chesterwell-the-grove.merseahomes.think-online.co.uk/site'
        },
        {
            name: 'The Oaks',
            logoPath: oaksLogo,
            bgPath: oaksBg,
            imagePath: oaksImage,
            url: 'https://chesterwell-oaks.merseahomes.think-online.co.uk/site'
        },
    ]
}

export type {Development}
export {config};