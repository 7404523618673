// Styles
import styles from './Button.module.scss';

// Icons
import arrowIcon from '../../images/icons/arrow-right.svg'
import arrowIconWhite from '../../images/icons/arrow-right-white.svg'

// Vender
import cx from 'classnames';

interface ButtonProps {
  text: string,
  href: string,
  style: 'light' | 'dark'
}

function Button({text, href, style} : ButtonProps) {

  return (
      <a 
        className={cx(styles.Button, {[styles.Dark] : style === 'dark'})}
        href={href}
      >
        {text}
        <img src={style === "light" ? arrowIcon : arrowIconWhite} alt="" className={styles.Icon}/>
      </a>
  );
}

export default Button;
